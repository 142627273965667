// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_LANGUAGE = '@customization/SET_LANGUAGE';
export const LOGIN = '@authentication/LOGIN';
export const LOGOUT = '@authentication/LOGOUT';
export const REFRESH_TOKEN = '@authentication/REFRESH_TOKEN';
export const VERIFY_EMAIL = '@authentication/VERIFY_EMAIL';
export const LOCK_MENU = '@menu/SET_MENU_LOCKED';
export const STORE_MENU = '@menu/STORE_MENU';
export const RESET_MENU = '@menu/RESET_MENU';
export const SET_BREADCRUMBS = '@breadcrumbs/SET_BREADCRUMBS';
export const RESET_BREADCRUMBS = '@breadcrumbs/RESET_BREADCRUMBS';
