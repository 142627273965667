import axiosInstance from '../axiosInstance';

export const backofficeApi = {
    post: (path, params, cancelToken) =>
        axiosInstance
            .post(`/backoffice${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, cancelToken) =>
        axiosInstance
            .get(`/backoffice${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getParams = async (params) => backofficeApi.post('/getparams', params);

export const getData = async (params) => backofficeApi.post('/getdata', params);

export const getLookups = async (params) => backofficeApi.post('/getlookups', params);

export const commitChanges = async (params) => backofficeApi.post('/commitchanges', params);

export const getForm = async (params) => backofficeApi.post('/getform', params);
