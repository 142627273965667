// assets
import { IconDashboard, IconTool, IconUser, IconUsers, IconReportAnalytics, IconHome2, IconSettings } from '@tabler/icons';

// constant
const icons = { IconHome2, IconTool, IconUsers, IconReportAnalytics, IconDashboard, IconUser, IconSettings };
// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    children: [
        {
            id: 'Home.View',
            title: 'Home',
            type: 'item',
            url: '/home',
            icon: icons.IconHome2
        },
        {
            id: 'Users.View',
            title: 'Users',
            type: 'item',
            url: '/users/view',
            icon: icons.IconUser,
            breadcrumbs: true,
            subject: 'Users'
        },
        {
            id: 'Roles.View',
            title: 'Roles',
            type: 'item',
            url: '/roles/view',
            icon: icons.IconUsers,
            breadcrumbs: true,
            subject: 'Roles'
        },
        {
            id: 'AccountSettings.View',
            title: 'AccountSettings',
            type: 'item',
            url: '/user/settings',
            icon: icons.IconSettings
        }
    ]
};

export default dashboard;
