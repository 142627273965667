import { useSelector } from 'react-redux';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, StyledEngineProvider, Slide } from '@mui/material';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';

import { QueryClient, QueryClientProvider } from 'react-query';

import { SnackbarProvider } from 'notistack';

// permissions
import { PermissionProvider } from 'context/permissionContext';

import { UserProvider } from 'context/userContext';

const queryClient = new QueryClient();

// ==============================|| APP ||============================== //

const App = () => {
    const customization = useSelector((state) => state.customization);
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes(customization)}>
                <SnackbarProvider
                    maxSnack={3}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left'
                    }}
                    TransitionComponent={Slide}
                >
                    <QueryClientProvider client={queryClient}>
                        <CssBaseline />
                        <NavigationScroll>
                            <UserProvider>
                                <PermissionProvider>
                                    <Routes />
                                </PermissionProvider>
                            </UserProvider>
                        </NavigationScroll>
                    </QueryClientProvider>
                </SnackbarProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
