import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import { Logo } from '@qubiteq/qublib';
// material-ui
import qubiteq from 'assets/images/qubiteq.png';
// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        <Logo src={qubiteq} />
    </ButtonBase>
);

export default LogoSection;
