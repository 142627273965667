import React from 'react';
import { qrCode } from 'apis/controllers/queue';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router';
import { useQuery, useQueryClient } from 'react-query';
import { HubConnectionBuilder } from '@microsoft/signalr';

const QrCode = () => {
    const { id } = useParams();
    const queryClient = useQueryClient();
    const qrQuery = useQuery(['qrCode', id], async ({ queryKey }) => {
        try {
            const [, id] = queryKey;
            console.log(id);
            const qr = await qrCode({ id: parseInt(id, 10) });
            console.log('QR CODE', qr);
            return qr;
        } catch (error) {
            console.log('THIS IS ERROR', error);
            throw error;
        }
    });

    React.useEffect(() => {
        const url = `${process.env.REACT_APP_BASE_URL}/hub/notificationhub?group=qrCode`;
        const connection = new HubConnectionBuilder().withUrl(url).withAutomaticReconnect().build();

        console.log('SignalrCOnnection', connection.state);
        connection
            .start()
            .then((hey) => console.log('STARTED', hey))
            .catch((err) => console.log('Connection error', err));

        connection.on('WebNotification', (data) => {
            if (data?.invalidateQueries) {
                queryClient.invalidateQueries(['qrCode', id]);
            }
        });

        return () => {
            connection.off('WebNotification');
        };
    }, [id, queryClient]);

    return (
        <div style={{ height: '100vh', display: 'flex', direction: 'vertical', alignItems: 'center', justifyContent: 'center' }}>
            {
                {
                    success: <QRCode value={qrQuery.data?.code} />,
                    loading: <>Loading...</>,
                    error: <>Error...</>
                }[qrQuery.status]
            }
        </div>
    );
};

export default QrCode;
