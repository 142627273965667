import axiosInstance from '../axiosInstance';

export const queueApi = {
    post: (path, params, { cancelToken, version }) =>
        axiosInstance
            .post(`/${version ? `${version}/` : ''}queue${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            }),
    get: (path, params, { cancelToken, version }) =>
        axiosInstance
            .get(`/${version ? `${version}/` : ''}queue${path}`, params, {
                cancelToken: cancelToken?.token
            })
            .then((res) => {
                if (res?.data?.error) throw res.data;
                return res?.data;
            })
            .catch((err) => {
                throw err.error ? err : err?.response?.data;
            })
};

export const getFromId = async (params) => queueApi.get('/fromid', { params }, { version: 'v1' });

export const getFromStatus = async (params) => queueApi.get('/fromstatus', { params }, { version: 'v1' });

export const getItemHistory = async (params) => queueApi.get('/itemhistory', { params }, { version: 'v1' });

export const dequeue = async (params) => queueApi.post('/dequeue', params, { version: 'v1' });

export const enqueue = async (params) => queueApi.post('/enqueue', params, { version: 'v1' });

export const reEnqueue = async (params) => queueApi.post('/reenqueue', params, { version: 'v1' });

export const reorder = async (params) => queueApi.post('/reorder', params, { version: 'v1' });

export const callCustomer = async (params) => queueApi.post('/callcustomer', params, { version: 'v1' });

export const notifyCustomer = async (params) => queueApi.post('/notifyCustomer', params, { version: 'v1' });

export const updateItem = async (params) => queueApi.post('/editItem', params, { version: 'v1' });

export const enqueueableQueues = async (params) => queueApi.get('/enqueueable', { params }, { version: 'v1' });

export const changeQueue = async (params) => queueApi.post('/changequeue', params, { version: 'v1' });

export const qrCode = async (params) => queueApi.get('/qrCode', { params }, { version: 'v1' });

export const notificationMessages = async (params) => queueApi.get('/notificationmessages', { params }, { version: 'v1' });

export const toggleActivation = async (params) => queueApi.post('/toggleactivation', params, { version: 'v1' });
