import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false
        },
        resources: {
            en: {
                translation: {
                    Home: 'Home',
                    Users: 'Users',
                    Roles: 'Roles',
                    Analytics: 'Analytics',
                    AccountSettings: 'Account Settings',
                    GoodMorning: 'Good Morning',
                    GoodEvening: 'Good Evening',
                    Dashboard: 'Dashboard',
                    SelectLanguage: 'Select language',
                    EnterCredsContinue: 'Enter your credentials to continue',
                    LoginEmailUsername: 'Login with email or username',
                    Login: 'Login',
                    Logout: 'Logout',
                    ForgotPassword: 'Forgot my password',
                    DontHaveAcount: "Don't  have an account?",
                    LoginSuccess: 'Login successful',
                    SomethingWrong: 'Something went wrong!',
                    Email: 'Email',
                    Password: 'Password',
                    ConfirmPassword: 'Confirm password',
                    RequiredField: 'Required field',
                    CreateAccount: 'Create account',
                    YourCreds: 'Enter your credentials',
                    AcceptTerms: 'Accept terms and conditions',
                    AcceptTermsError: 'You have to accept the terms and conditions',
                    AlreadyAccount: 'Already have an account?',
                    SignUp: 'Sign up',
                    Firstname: 'Firstname',
                    Lastname: 'Lastname',
                    PhoneNumber: 'Phone number',
                    InvalidPhoneNumber: 'Invalid phone number',
                    PasswordsNotMatching: 'Passwords do not match',
                    InvalidDate: 'Invalid date',
                    InvalidPhone: 'Invalid phone',
                    InvalidEmail: 'Invalid email',
                    SiteUnavailable: 'Site is temporarily unavailable :(',
                    PageNotFound: 'Page not found :(',
                    PageUnderConstruction: '🚚 Page is under construction 🚚',
                    Application: 'Application',
                    Queues: 'Queues',
                    Dob: 'Date of birth',
                    Gender: 'Gender',
                    Male: 'Male',
                    Female: 'Female',
                    Other: 'Other',
                    CustomerDetails: 'Customer Details',
                    AddCustomer: 'Add Customer',
                    Yes: 'Yes',
                    CustomerNotFound: 'Customer not found',
                    CreateNewCustomerPrompt: 'Do you wish to create new customer?',
                    Name: 'Name',
                    Comments: 'Comments',
                    Timestamp: 'Timestamp',
                    People: 'People',
                    Status: 'Status',
                    Actions: 'Actions',
                    NewRecord: 'New record',
                    CreateAndAddCustomer: 'Create & Add Customer',
                    Title: 'Title',
                    Body: 'Body',
                    SendMessage: 'Send message to customer',
                    SendCustom: 'Send custom message',
                    Send: 'Send',
                    UpdatePeople: 'Update people',
                    ChangeQueue: 'Change queue',
                    SelectQueue: 'Select queue',
                    CreateUserMsg: 'Create user',
                    Details: 'Details',
                    AddRoles: 'Add roles',
                    Description: 'Description',
                    Abbr: 'Abbr',
                    Back: 'Back',
                    Next: 'Next',
                    Reset: 'Reset',
                    Save: 'Save',
                    Permissions: 'Permissions',
                    Search: 'Search',
                    Active: 'Active',
                    FailedToUpdate: 'Failed to update',
                    Customers: 'Customers',
                    QueueHistory: 'History',
                    History: 'History',
                    Called: 'Called',
                    Coming: 'Coming',
                    Fulfilled: 'Fufilled',
                    InQueue: 'In queue',
                    OffQueue: 'Off queue',
                    Cancelled: 'Dequeued',
                    Dequeued: 'Cancelled',
                    RedirectToHome: 'Back to Home',
                    ResponseDescription: 'Response type',
                    QueueName: 'Queue name',
                    Enqueued: 'Enqueued',
                    ChangedQueue: 'Changed Queue',
                    Notified: 'Notified',
                    Reenqueued: 'Reenqueued',
                    TComing: 'Coming',
                    TCalled: 'Called',
                    // Work around please fix, those enums should be reversed
                    TDequeued: 'Cancelled',
                    TCancelled: 'Dequeued',
                    TFulfilled: 'Fulfilled',
                    CustomerAlreadyEnqueued: 'Customer already enqueued',
                    InsertedByCustomer: 'From App'
                }
            },
            gre: {
                translation: {
                    Home: 'Αρχική',
                    Users: 'Χρήστες',
                    Roles: 'Ρόλοι',
                    Analytics: 'Aναλυτικά στοιχεία',
                    AccountSettings: 'Ρυθμίσεις λογαριασμού',
                    GoodMorning: 'Καλημέρα',
                    GoodEvening: 'Καλησπέρα',
                    Dashboard: 'Διαχείριση',
                    SelectLanguage: 'Επιλογή γλώσσας',
                    EnterCredsContinue: 'Εισάγεται τα στοιχεία σας για να συνεχίσετε',
                    LoginEmailUsername: 'Σύνδεση μέσω Email ή Όνομα Χρήστη',
                    Login: 'Σύνδεση',
                    Logout: 'Αποσύνδεση',
                    ForgotPassword: 'Ξέχασα τον κωδικό μου',
                    DontHaveAcount: 'Δεν έχετε λογαριασμό;',
                    LoginSuccess: 'Επιτυχία σύνδεσης',
                    SomethingWrong: 'Κάτι πήγε λάθος',
                    Email: 'Δ/νση Ηλ. Ταχυδρομίου',
                    Password: 'Κωδικός πρόσβασης',
                    ConfirmPassword: 'Επιβεβαιώση κωδικού πρόσβασης',
                    RequiredField: 'Υποχρεωτικό πεδίο',
                    CreateAccount: 'Δημιουργία λογαριασμού',
                    YourCreds: 'Καταχωρήστε τα στοιχεία σας',
                    AcceptTerms: 'Αποδεχθείτε τους όρους χρήσης',
                    AcceptTermsError: 'Πρέπει να αποδεχθείτε τους όρους χρήσης',
                    AlreadyAccount: 'Έχετε ήδη λογαριασμό;',
                    SignUp: 'Εγγραφή',
                    Firstname: 'Όνομα',
                    Lastname: 'Επώνυμο',
                    PhoneNumber: 'Αριθμός τηλεφώνου',
                    InvalidPhoneNumber: 'Μη έγκυρος αριθμός τηλεφώνου',
                    PasswordsNotMatching: 'Οι κωδικοί δεν ταιριάζουν',
                    InvalidDate: 'Μη έγκυρη ημερομηνία',
                    InvalidPhone: 'Μη έγκυρος αριθμός τηλεφώνου',
                    InvalidEmail: 'Μη έγκυρο email',
                    SiteUnavailable: 'Η ιστοσελίδα είναι προσωρινά μη διαθέσιμη :(',
                    PageNotFound: 'Η σελίδα δεν βρέθηκε :(',
                    PageUnderConstruction: '🚚 Η σελίδα είναι υπό κατασκευή 🚚',
                    Application: 'Εφαρμογή',
                    Queues: 'Ουρές',
                    Dob: 'Ημερομηνία γέννησης',
                    Gender: 'Φύλο',
                    Male: 'Άνδρας',
                    Female: 'Γυναίκα',
                    Other: 'Άλλο',
                    CustomerDetails: 'Στοιχεία πελάτη',
                    AddCustomer: 'Προσθήκη πελάτη',
                    No: 'No',
                    CustomerNotFound: 'Ο πελάτης δεν βρέθηκε',
                    CreateNewCustomerPrompt: 'Θέλετε να δημιουργήσετε καινούργιο;',
                    Name: 'Όνομα',
                    Comments: 'Σχόλια',
                    Timestamp: 'Ώρα',
                    People: 'Άτομα',
                    Status: 'Κατάσταση',
                    Actions: 'Ενέργειες',
                    NewRecord: 'Νέα εγγραφή',
                    CreateAndAddCustomer: 'Δημιουργία & Προσθήκη Πελάτη',
                    Title: 'Τίτλος',
                    Body: 'Περιεχόμενο',
                    SendMessage: 'Στείλε μήνυμα στον πελάτη',
                    SendCustom: 'Στείλε προσαρμοσμένο μήνυμα',
                    Send: 'Αποστολή',
                    UpdatePeople: 'Ενημέρωση αριθμού ατόμων',
                    ChangeQueue: 'Αλλαγή ουράς',
                    SelectQueue: 'Επιλογή ουράς',
                    CreateUserMsg: 'Δημιουργία νέου χρήστη',
                    Details: 'Πληροφορίες',
                    AddRoles: 'Προσθήκη ρόλων',
                    Description: 'Περιγραφή',
                    Abbr: 'Συντόμευση',
                    Back: 'Πίσω',
                    Next: 'Επόμενο',
                    Reset: 'Επαναφορά',
                    Save: 'Αποθήκευση',
                    Permissions: 'Δικαιώματα',
                    Search: 'Αναζήτηση',
                    Active: 'Ενεργή',
                    FailedToUpdate: 'Αποτυχία ενημέρωσης',
                    Customers: 'Πελάτες',
                    QueueHistory: 'Ιστορικό',
                    History: 'Ιστορικό',
                    Called: 'Ειδοποιήθηκε',
                    Coming: 'Έρχεται',
                    Fulfilled: 'Εξυπηρετήθηκε',
                    Cancelled: 'Αποχώρησε',
                    Dequeued: 'Ακυρώθηκε',
                    RedirectToHome: 'Πίσω στην Αρχική',
                    InQueue: 'Σε ουρά',
                    OffQueue: 'Εκτός ουράς',
                    ResponseDescription: 'Τύπος απάντησης',
                    QueueName: 'Όνομα ουράς',
                    Enqueued: 'Μπήκε σε ουρά',
                    ChangedQueue: 'Άλλαξε ουρά',
                    Notified: 'Ειδοποιήθηκε',
                    Reenqueued: 'Ξανά μπήκε σε ουρά',
                    TComing: 'Επιβεβαιώσεις',
                    TCalled: 'Ειδοποιήσεις',
                    TDequeued: 'Ακυρώσεις',
                    TCancelled: 'Αποχωρήσεις',
                    TFulfilled: 'Ολοκληρώθηκαν',
                    CustomerAlreadyEnqueued: 'Ο πελάτης είναι ήδη σε ουρά',
                    InsertedByCustomer: 'Από App'
                }
            }
        }
    });

export default i18n;
