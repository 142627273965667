import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

// project imports
import { Loadable } from '@qubiteq/qublib';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/authentication/authentication3/Register3')));
const ResetPassword = Loadable(lazy(() => import('views/authentication/forgotPassword')));
const UserInvitation = Loadable(lazy(() => import('views/authentication/userInvitation')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = () => [
    {
        path: '/',
        element: <MinimalLayout />,
        children: [
            {
                path: '/login',
                element: <AuthLogin3 />
            },
            {
                path: '/register',
                element: <AuthRegister3 />
            },
            {
                path: '/reset/:token',
                element: <ResetPassword />
            },
            {
                path: '/invite',
                element: <UserInvitation />
            },
            {
                path: '/',
                element: <Navigate to="/login" />
            }
        ]
    },
    {
        path: '*',
        element: <Navigate to="/login" />
    }
];

export default AuthenticationRoutes;
