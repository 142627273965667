import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Box, Typography, Stack, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from 'assets/scss/_themes-vars.module.scss';

const BreadcrumbSection = () => {
    const { breadcrumbs, info } = useSelector((state) => state.breadcrumbs);
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.up('sm'));
    return (
        <Box sx={{ p: 1, pl: 2 }}>
            {matchDownMd && (
                <Stack direction="row" spacing={1} alignItems="center">
                    <Breadcrumbs aria-label="breadcrumb">
                        {breadcrumbs?.map((item, key) => {
                            const name = item.name ? item.name : '';
                            const path = item.path ? item.path : pathname;
                            return (
                                <Box
                                    key={key}
                                    sx={{
                                        p: 1,
                                        cursor: 'pointer',
                                        borderRadius: 2,
                                        color: 'black',
                                        '&:hover': {
                                            background: colors.primaryLight
                                        }
                                    }}
                                >
                                    <Typography
                                        underline="hover"
                                        sx={{
                                            userSelect: 'none',
                                            fontWeight: 500
                                        }}
                                        onClick={() => navigate(path)}
                                    >
                                        {name}
                                    </Typography>
                                </Box>
                            );
                        })}
                    </Breadcrumbs>
                    <Typography
                        sx={{
                            fontWeight: 500
                        }}
                    >
                        {info}
                    </Typography>
                </Stack>
            )}
        </Box>
    );
};

export default BreadcrumbSection;
