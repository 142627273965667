// project imports
import VerificationPrompt from 'views/verification';

import { PermissionDenied } from './components';

import { useMe } from 'hooks/useMe';

export const withPermission =
    (Component, { action, subject, unrestricted }) =>
    (props) => {
        const { whoami, isLoading, isError } = useMe();
        const isEmailVerified = whoami?.isEmailVerified;
        const hasPermission = whoami?.permissions?.can(action, subject);

        if (isLoading || isError) {
            return null;
        }
        if (!isEmailVerified) {
            return <VerificationPrompt />;
        }
        if (!hasPermission && !unrestricted) {
            return <PermissionDenied />;
        }
        return <Component {...props} />;
    };
