import PropTypes from 'prop-types';

// i18n
import { Trans } from 'react-i18next';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Divider, List, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';
import NavCollapse from '../NavCollapse';

// permissions
import { Can } from 'context/permissionContext';

// ==============================|| SIDEBAR MENU LIST GROUP ||============================== //

const NavGroup = ({ item }) => {
    const theme = useTheme();
    // menu list collapse & items
    const items = item.children?.map((menu) => {
        switch (menu.type) {
            case 'collapse':
                return <NavCollapse key={menu.id} menu={menu} level={1} />;
            case 'item':
                if (menu.subject) {
                    return (
                        <Can I="View" a={menu.subject} key={menu.id}>
                            <NavItem item={menu} level={1} />
                        </Can>
                    );
                }
                return <NavItem key={menu.id} item={menu} level={1} />;

            default:
                return (
                    <Typography key={menu.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return (
        <Can I="View" a={item.subject} key={item.id} passThrough>
            {(allowed) =>
                allowed || !item.subject ? (
                    <>
                        <List
                            subheader={
                                item.title && (
                                    <Typography variant="caption" sx={{ ...theme.typography.menuCaption }} display="block" gutterBottom>
                                        <Trans i18nKey={item.title}>{item.title}</Trans>
                                        {item.caption && (
                                            <Typography
                                                variant="caption"
                                                sx={{ ...theme.typography.subMenuCaption }}
                                                display="block"
                                                gutterBottom
                                            >
                                                {item.caption}
                                            </Typography>
                                        )}
                                    </Typography>
                                )
                            }
                        >
                            {items}
                        </List>

                        {/* group divider */}
                        <Divider sx={{ mt: 0.25, mb: 1.25 }} />
                    </>
                ) : null
            }
        </Can>
    );
};

NavGroup.propTypes = {
    item: PropTypes.object
};

export default NavGroup;
