//  mui
import { Stack, Grid, Typography, Box, Button } from '@mui/material';

// colors
import colors from 'assets/scss/_themes-vars.module.scss';

// Apis
import { auth } from 'apis';

//  react-router-dom
import { useNavigate } from 'react-router-dom';

// store
import { VERIFY_EMAIL } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';

import { PinInput } from '@qubiteq/qublib';

import { useSnackbar } from 'notistack';

import { useMe } from 'hooks/useMe';

import { useQueryClient } from 'react-query';

const Verification = () => {
    const dispatch = useDispatch();
    const { isLoggedIn } = useSelector((state) => state.auth);
    const queryClient = useQueryClient();
    const { whoami } = useMe();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const userIdentifier = whoami?.email;

    const onComplete = (pin) => {
        auth.ValidateVerificationPin({ userIdentifier, value: Number(pin) })
            .then((res) => {
                if (res.success === 1) {
                    if (!isLoggedIn) {
                        navigate('/login');
                    }
                    dispatch({ type: VERIFY_EMAIL });
                    queryClient.invalidateQueries('me');
                    enqueueSnackbar('Το email επιβεβαιώθηκε με επιτυχία', {
                        variant: 'success'
                    });
                }
                if (res.success === 0) {
                    enqueueSnackbar('Το email δεν επιβεβαιώθηκε', {
                        variant: 'error'
                    });
                }
            })
            .catch((error) => {
                console.log('error', error);
            });
    };

    return (
        <Grid container justifyContent="center" alignItems="center">
            <Grid item container justifyContent="center" xs={12} sx={{ p: 1 }}>
                <Box sx={{ p: 2, background: 'white', borderRadius: 2 }}>
                    <Stack spacing={1} justifyContent="center" alignItems="center">
                        <Typography variant="h1">Enter the verification code</Typography>
                        <Box sx={{ height: 5, width: '100%', background: colors.primaryMain }} />
                        <Typography variant="subtitle1">An email with the verification code was sent to your email account</Typography>
                        <PinInput
                            length={6}
                            focus
                            inputStyle={{
                                borderColor: colors.primaryMain,
                                borderRadius: 8,
                                width: 40,
                                height: 40
                            }}
                            type="numeric"
                            onComplete={onComplete}
                        />
                    </Stack>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            auth.sendVerificationEmail({ emailTo: userIdentifier })
                                .then((res) => {
                                    console.log('res', res);
                                    enqueueSnackbar('Το PIN έχει σταλεί στο email σας', {
                                        variant: 'success'
                                    });
                                })
                                .catch((error) => {
                                    console.log('error', error);
                                });
                        }}
                        sx={{ mt: 4 }}
                    >
                        Επαναποστολή PIN
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default Verification;
